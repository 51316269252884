import React from "react"
import router from "next/router"

// components
import ButtonPrimary from "src/reusableComponents/ButtonPrimary/ButtonPrimary"
import Layout from "src/reusableComponents/Layout"
import LayoutEdit from "src/reusableComponents/LayoutEdit"

// styles
import { StyledBlock } from "./NotFound.styles"

const NotFound = () => (
    <LayoutEdit>
        <Layout showTopAnalysis showNewsSlider>
            <StyledBlock>
                <p className="block-top-subtitle">Oops, looks like you've reached a dead end.</p>
                <span className="block-title">404</span>
                <p className="block-bottom-subtitle">Page not found</p>
                <div className="block-action">
                    <ButtonPrimary
                        onClick={() => {
                            router.push("/")
                        }}
                    >
                        Main Page
                    </ButtonPrimary>
                </div>
            </StyledBlock>
        </Layout>
    </LayoutEdit>
)

export default NotFound
