import { StyledAnalysisBox } from "./AnalysisBox.styles"

type Props = {
    children: JSX.Element | string | Element
}

const AnalysisBox = ({ children }: Props) => {
    return <StyledAnalysisBox>{children}</StyledAnalysisBox>
}

export default AnalysisBox
