import styled from "styled-components"

export const StyledAnalysisBox = styled.div`
    padding: 18px 18px 10px;
    background: #ffffff;
    box-shadow: 0 4px 34px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-bottom: 20px;

    .negativeMargin {
        margin: -18px -18px 0;
    }

    .headingWrapper {
        background-color: #e5e5e5;
        padding: 12px 18px 8px;
        border-radius: 10px 10px 0 0;
        position: sticky;
        top: -8px;
        z-index: 1;
    }

    .bodyWrapper {
        font-weight: 400;
        margin: 16px 27px 10px;
    }

    .fullWidth {
        width: 100%;
    }

    .hypothesisWrapper {
        margin: 10px 19px 0;
    }

    @media (max-width: 575px) {
        padding: 20px 20px 10px;

        .negativeMargin {
            margin: -20px -20px 0;
        }

        .bodyWrapper {
            margin: 10px;
        }
    }
`
