import styled from "styled-components"
import { colors } from "../../constants/colors"

export const StyledLayout = styled.div`
    display: flex;
`

export const StyledContent = styled.div`
    margin-left: 21%;
    margin-right: 2%;
    position: relative;
    width: 77%;
    margin-bottom: 15px;
    margin-top: 15px;

    * {
        box-sizing: border-box;
    }

    .section {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: underline;
        &:hover {
            color: ${colors.green};
        }
    }

    h2 {
        margin-bottom: 15px;
        margin-top: 20px;
    }

    h3 {
        margin-bottom: 15px;
        margin-top: 20px;

        a {
            font-size: 28px;
        }
    }

    h4 {
        margin-bottom: 10px;
    }

    p {
        line-height: 28px;
        margin-bottom: 10px;
    }

    ol,
    ul {
        margin: 10px 0 15px;
        padding-left: 18px;
    }

    li {
        margin-bottom: 10px;

        p {
            line-height: 24px;
        }
    }

    iframe {
        border: none;
    }

    .section.withMargin {
        margin-bottom: 40px;
    }

    .staticHero {
        position: relative;
    }

    .heroBackground {
        height: 0;
        padding-bottom: 39.5%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
    }

    .heroForeground {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;

        h1 {
            text-shadow: 2px 3px 3px #000;
        }
    }

    .overlay {
        background-color: rgba(0, 0, 0, 0.34);
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    .layoutWithVideo {
        display: flex;
        flex-direction: column;

        > div:first-child {
            padding-right: 30px;
        }

        .video {
            border-radius: 10px;
            height: 0;
            max-width: 100%;
            min-width: 40.5%;
            padding-bottom: 41%;
            overflow: hidden;
            position: relative;
            width: 79.2%;
            margin: 30px auto 30px;

            &.videoTrackRecord {
                margin-bottom: 0;
            }

            iframe {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    .videoWrapper {
        min-width: 40.5%;
        width: 40.5%;

        .video {
            padding-bottom: 63.05%;
            width: 100%;
        }
    }

    .videoSubtitle {
        font-weight: 300;
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
    }

    .contactsRow {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .emailLink {
        display: flex;

        span {
            padding-left: 8px;
        }
    }

    .socialLink {
        display: inline-flex;
        margin-left: 10px;
    }

    .categoryTitle {
        border-bottom: 1px solid ${colors.lineGrey};
        margin: 20px 0;
        padding-bottom: 10px;

        h2 {
            font-size: 30px;
            margin-bottom: 0;
            font-weight: 700;
        }
    }

    .imageWrapper,
    .tableWrapper {
        display: flex;
        justify-content: center;
        margin: 30px 0;
    }

    .imageWrapper img {
        max-width: 100%;
    }

    .btnLinkWrapper {
        button {
            font-weight: 400;
        }
        display: inline-block;
    }

    .tableWrapper {
        overflow: auto;
        max-width: 100%;
    }

    table {
        border-collapse: collapse;
    }

    td {
        border: 1px solid ${colors.lineGrey};
        padding: 4px 10px;
    }

    .alignCenter {
        text-align: center;
    }

    .paddingLeftRecord {
        padding-left: 10px;
    }

    .latinList {
        list-style: lower-latin;
    }

    .romanList {
        list-style: lower-roman;
    }

    .weightBold {
        font-weight: 600;
    }

    .withMargin {
        margin-bottom: 20px;
    }

    .paddingLeft {
        padding-left: 40px;
    }

    .tableWrapper thead td {
        font-weight: bold;
    }

    .pageTitle {
        h1 {
            text-shadow: 2px 3px 3px #000;
        }
    }

    @media (max-width: 1024px) {
        .videoSubtitle {
            max-width: 500px;
        }
    }

    @media (max-width: 992px) {
        box-sizing: border-box;
        margin: 40px 0 0;
        padding: 0 30px;
        width: 100%;

        .layoutWithVideo {
            flex-direction: column;

            > div:first-child {
                padding-right: 0;
            }

            .videoWrapper {
                margin: 30px auto 0;
                width: 79.2%;

                .video {
                    width: 100%;
                }
            }
        }
    }

    .formula {
        align-items: center;
        display: flex;
        margin: 10px 0;

        > * {
            padding: 0 5px;
        }
    }

    .fraction {
        align-items: center;
        display: flex;
        flex-direction: column;

        .top,
        .bottom {
            text-align: center;
            width: 100%;
        }

        .bottom {
            border-top: 2px solid ${colors.black};
        }
    }

    .tableWrapper {
        display: block;
    }

    table {
        width: 100%;
    }

    @media (max-width: 767px) {
        margin-top: 20px;
        padding: 0 15px;
    }

    @media (max-width: 575px) {
        .heroBackground {
            padding-bottom: 270px;
        }

        .section {
            margin-bottom: 30px;
        }

        h2 {
            margin-bottom: 20px;
            margin-top: 20px;
        }

        .contactsRow {
            align-items: flex-start;
            flex-direction: column;
        }

        .socialLink {
            margin: 15px 10px 0 0;
        }

        .layoutWithVideo {
            .video {
                padding-bottom: 60%;
                width: 100%;
            }
        }
    }
`
