import { StyledLink, StyledSideMenu } from "../../styles/SideMenu"
import useSideMenu from "../../hooks/useSideMenu"
import { SideMenuItem } from "../../types/menu"

type Props = {
    menuItems: SideMenuItem[]
}

const SideMenu = ({ menuItems }: Props) => {
    const [activeElement, marginTop, hideHeader, useMargin, scrollIntroView] =
        useSideMenu(menuItems)

    return (
        <StyledSideMenu changePosition={hideHeader} marginTop={marginTop} useMargin={useMargin}>
            <ul id="sideMenu">
                {menuItems.map(({ short_name, name, className }, index) => (
                    <StyledLink
                        href={`#${short_name}`}
                        activeElement={activeElement}
                        onClick={(e) => scrollIntroView(e, short_name)}
                        key={index}
                    >
                        <li className={className}>{name}</li>
                    </StyledLink>
                ))}
            </ul>
        </StyledSideMenu>
    )
}

export default SideMenu
