import styled from "styled-components"
import { colors } from "../../constants/colors"

type Props = {
    changePosition: boolean
    marginTop: number
    rtl?: boolean
    useMargin: boolean
}

export const StyledSideMenu = styled.div<Props>`
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    width: 19%;
    top: 0;
    bottom: 0;
    // background-color: ${colors.headingBg};
    margin-top: ${({ marginTop, changePosition, useMargin }) => {
        if (!useMargin) return 109
        return changePosition ? 0 : marginTop
    }}px;
    transition: margin-top 0.28s ease;
    max-height: 100vh;

    ul {
        margin: 0;
        padding: 30px 0 50px;
        li {
            list-style-type: none;
        }
        a {
            text-decoration: none;
        }
    }

    .mainItem,
    .mainItemSmall,
    .subItem {
        box-sizing: border-box;

        position: relative;
        width: 100%;

        &:after {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5.5px 0 5.5px 7px;

            content: "";
            display: none;
            position: absolute;
            right: ${(props) => (props.rtl ? "initial" : "14px")};
            left: ${(props) => (props.rtl ? "14px" : "initial")};
            transform: ${(props) => (props.rtl ? "rotate(180deg)" : "none")};

            top: calc(50% - 5.5px);
        }
    }

    .mainItem:after,
    .mainItemSmall:after {
        border-color: transparent transparent transparent ${colors.black};
    }

    .subItem:after {
        border-color: transparent transparent transparent ${colors.grey};
    }

    .mainItem {
        // border-bottom: 1px solid #7e7e7e;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: ${colors.black};
        text-align: ${(props) => (props.rtl ? "right" : "left")};

        padding: ${(props) => (props.rtl ? "8px 10% 4px 25px" : "8px 25px 4px 10%")};
    }

    .mainItemSmall {
        border-bottom: 1px solid #f1f1f1;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: ${colors.black};
        text-align: ${(props) => (props.rtl ? "right" : "left")};

        padding: ${({ rtl }) => (rtl ? "8px 13% 4px 25px" : "8px 25px 4px 13%")};
    }

    .subItem {
        // background-color: ${colors.subHeadingBg};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: ${colors.grey};
        text-align: ${(props) => (props.rtl ? "right" : "left")};

        padding: ${({ rtl }) => (rtl ? "8px 13% 4px 25px" : "8px 25px 4px 13%")};

        // border-bottom: 1px solid ${colors.lineGrey};

        p {
            margin: 0;
        }
    }

    .extraPadding {
        padding-left: 16%;
    }

    .bold {
        font-weight: 700;
    }

    @media (max-width: 1160px) {
        margin-top: ${({ marginTop, changePosition, useMargin }) => {
            if (!useMargin) return 58
            return changePosition ? 0 : marginTop
        }}px;
    }

    @media (max-width: 992px) {
        display: none;
    }
`

export const StyledLink = styled.a<{ href: string; activeElement: string }>`
    :first-child {
        .mainItem {
            border-radius: 2px 2px 0 0;
        }
    }

    :last-child {
        .mainItem {
            border-radius: 0 0 2px 2px;
        }
    }

    .mainItem,
    .mainItemSmall,
    .subItem {
        :after {
            display: ${({ href, activeElement }) =>
                href === activeElement ? "inline-flex" : "none"};
        }
    }
`
