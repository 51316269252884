import { isBrowser, removeSpaces } from "../helpers"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AppState } from "../redux/reducers"

const useSideMenu = (
    menuElements?: Array<{ [key: string]: string }>
): [
    string,
    number,
    boolean,
    boolean,
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, short_name: string) => void
] => {
    const [activeElement, setActiveElement] = useState("")
    const [marginTop, setMarginTop] = useState(0)
    const [useMargin, setUseMargin] = useState(false)

    const hideHeader = useSelector((appState: AppState) => appState.ui.hideHeader)

    const handleScroll = () => {
        if (!isBrowser()) return

        const header = document.getElementById("header")

        setMarginTop(header?.clientHeight || 0)

        const onTop = !!document.documentElement?.scrollTop

        setUseMargin(onTop)

        menuElements?.forEach(({ short_name = "", name = "" }) => {
            const shortNameWithoutSpaces = removeSpaces(short_name || name.slice(0, 35))
            const element = document.getElementById(shortNameWithoutSpaces)
            if (element && (document.documentElement?.scrollTop || 0) >= element.offsetTop - 1) {
                setActiveElement(`#${shortNameWithoutSpaces}`)
            }
        })
    }

    useEffect(() => {
        if (!isBrowser()) return

        window.addEventListener("scroll", handleScroll)

        return () => window.removeEventListener("scroll", handleScroll)
    }, [menuElements?.length])

    const scrollIntroView = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        short_name: string
    ) => {
        e.preventDefault()

        const elementOffsetTop = window?.document?.getElementById(short_name)?.offsetTop
        const headerSize = window?.document?.getElementById("header")

        window.scrollTo({
            top: (elementOffsetTop || 0) + (hideHeader ? headerSize?.offsetHeight || 0 : 0),
            left: 0,
            behavior: "smooth",
        })
    }

    return [activeElement, marginTop, hideHeader, useMargin, scrollIntroView]
}

export default useSideMenu
