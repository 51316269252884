import { InfoPageReducerState } from "src/redux/actions/admin/infoPage/types"
import { GetServerSideProps } from "next"
import Head from "next/head"
import LayoutEdit from "src/reusableComponents/LayoutEdit"
import Layout from "src/reusableComponents/Layout"
import InfoPageComponent from "src/components/InfoPageComponent"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "src/redux/reducers"
import { getPublicInfoPage } from "src/services"
import { checkEdit } from "src/helpers"
import { initialUiState } from "src/redux/reducers/uiReducer"
import { useEffect, useState } from "react"
import { uiReducerState } from "src/redux/actions/ui/types"
import { getInfoPageByUrlActions } from "src/redux/actions/admin/infoPage/infoPage"
import LoaderWholePage from "src/reusableComponents/LoaderWholePage/LoaderWholePage"
import NotFound from "src/reusableComponents/NotFound/NotFound"
import Error from "next/error"

type Params = {
    url: string
}

type Props = {
    initialReduxState?: { infoPageReducer?: InfoPageReducerState } | { ui?: uiReducerState }
    hostUrl?: string
    referer?: string
    isEdit?: boolean
    slug?: string
}

export const getServerSideProps: GetServerSideProps<Props, Params> = async (context) => {
    const { req } = context
    const { host: hostUrl, referer = "" } = req?.headers

    const slug = context?.params?.url

    const isEdit = checkEdit(hostUrl)

    if (isEdit) {
        return {
            props: {
                hostUrl,
                referer,
                slug,
                initialReduxState: { ui: { ...initialUiState, isEdit } },
            },
        }
    }

    try {
        if (slug) {
            const { result: viewInfoPage } = await getPublicInfoPage(slug)

            return viewInfoPage
                ? {
                      props: {
                          hostUrl,
                          referer,
                          slug,
                          initialReduxState: { infoPageReducer: { viewInfoPage } },
                      },
                  }
                : { notFound: true }
        }

        return { notFound: true }
    } catch (e) {
        return { notFound: true }
    }
}

const InfoPage = ({ hostUrl, referer, slug }: Props) => {
    const infoPage = useSelector((state: AppState) => state.infoPageReducer.viewInfoPage)

    const { loader } = useSelector((state: AppState) => state.infoPageReducer)

    const { isEdit } = useSelector((state: AppState) => state.ui)
    const { access_token, isAdmin } = useSelector((state: AppState) => state.user)

    const dispatch = useDispatch()

    const [statusCode, setStatusCode] = useState<null | number>(null)

    useEffect(() => {
        if (window) {
            window?.history.pushState(
                {},
                infoPage.title || "",
                `/${infoPage.slug || infoPage.url} `
            )
        }
    }, [infoPage.slug])

    useEffect(() => {
        if (!isEdit || !slug) return

        dispatch(getInfoPageByUrlActions.request({ slug, setStatusCode }))
    }, [access_token, isAdmin])

    if (statusCode && statusCode >= 400 && statusCode != 404 && statusCode < 600) {
        return <Error statusCode={statusCode} />
    }

    if (statusCode && statusCode === 404) {
        return <NotFound />
    }

    return (
        <>
            <Head>
                <title>{!!infoPage.title && infoPage.title} | Rootclaim</title>

                <meta
                    name="description"
                    content={
                        infoPage.share_description ||
                        "Rootclaim helps people understand complex issues by combining the power of crowdsourced information with the mathematical validity of statistics."
                    }
                />
                <meta property="og:type" content="website" />
                {((!!hostUrl && !!infoPage.share_url) || !!referer) && (
                    <meta
                        property="og:url"
                        content={
                            !!hostUrl && !!infoPage.share_url
                                ? `${hostUrl}/${infoPage.share_url}`
                                : referer
                        }
                    />
                )}
                {(!!infoPage.share_title || !!infoPage.title) && (
                    <meta
                        property="og:title"
                        content={infoPage.share_title ? infoPage.share_title : infoPage.title}
                    />
                )}
                {!!infoPage.share_description && (
                    <meta property="og:description" content={infoPage.share_description} />
                )}
                {(!!infoPage.share_image_url ||
                    !!infoPage.image_url_desktop ||
                    !!infoPage.image_url_tablet ||
                    !!infoPage.image_url_mobile) && (
                    <meta
                        property="og:image"
                        content={
                            infoPage.share_image_url ||
                            infoPage.image_url_desktop ||
                            infoPage.image_url_tablet ||
                            infoPage.image_url_mobile ||
                            "http://res.cloudinary.com/dpowy5tkw/image/upload/v1493121178/calculating_reality.png"
                        }
                    />
                )}
                {(!!infoPage.share_image_url ||
                    !!infoPage.image_url_desktop ||
                    !!infoPage.image_url_tablet ||
                    !!infoPage.image_url_mobile) && (
                    <meta
                        property="og:image:secure_url"
                        content={
                            infoPage.share_image_url ||
                            infoPage.image_url_desktop ||
                            infoPage.image_url_tablet ||
                            infoPage.image_url_mobile ||
                            "http://res.cloudinary.com/dpowy5tkw/image/upload/v1493121178/calculating_reality.png"
                        }
                    />
                )}
                <meta property="fb:app_id" content="410563205820220" />
                <meta name="twitter:card" content="summary_large_image" />
                {(!!infoPage.share_title || !!infoPage.title) && (
                    <meta
                        name="twitter:title"
                        content={infoPage.share_title ? infoPage.share_title : infoPage.title}
                    />
                )}
                {!!infoPage.share_description && (
                    <meta name="twitter:description" content={infoPage.share_description} />
                )}
                <meta name="twitter:creator" content="@Rootclaim" />
                {(!!infoPage.share_image_url ||
                    !!infoPage.image_url_desktop ||
                    !!infoPage.image_url_tablet ||
                    !!infoPage.image_url_mobile) && (
                    <meta
                        name="twitter:image:src"
                        content={
                            infoPage.share_image_url ||
                            infoPage.image_url_desktop ||
                            infoPage.image_url_tablet ||
                            infoPage.image_url_mobile ||
                            "http://res.cloudinary.com/dpowy5tkw/image/upload/v1493121178/calculating_reality.png"
                        }
                    />
                )}
                <meta name="robots" content="index, follow" />
            </Head>

            <LayoutEdit>
                <Layout showTopAnalysis showNewsSlider>
                    <>
                        {!!loader && <LoaderWholePage />}
                        <InfoPageComponent />
                    </>
                </Layout>
            </LayoutEdit>
        </>
    )
}

export default InfoPage
