import { useSelector } from "react-redux"
import { AppState } from "src/redux/reducers"
import Image from "next/image"
import { isBrowser, openLinkInNewTab, removeSpaces } from "src/helpers"
import { getMenuItems } from "src/helpers/infoPages"
import { useEffect, useState } from "react"

// components
import SideMenu from "src/reusableComponents/SideMenu"
import AnalysisBox from "src/reusableComponents/AnalysisBox"
import { isMobile, isTablet } from "src/helpers"

// types
import { SideMenuItem } from "src/types/menu"

// styles
import { StyledH1 } from "src/styles/Text/Text.styles"
import { StyledContent, StyledLayout } from "src/styles/StaticContent"

type Props = {
    cover?: string
}

const InfoPageComponent = ({ cover }: Props) => {
    const { body, title, image_url_desktop, image_url_tablet, image_url_mobile } = useSelector(
        (state: AppState) => state.infoPageReducer.viewInfoPage
    )

    const [menuItems, setMenuItems] = useState<SideMenuItem[]>([])

    useEffect(() => {
        openLinkInNewTab("infoPageContent")
    }, [])

    useEffect(() => {
        if (!isBrowser()) {
            return
        }
        setMenuItems(getMenuItems())
    }, [body, title])

    const imageUrl = isTablet()
        ? image_url_tablet || image_url_desktop || image_url_mobile
        : isMobile()
        ? image_url_mobile || image_url_tablet || image_url_desktop
        : image_url_desktop || image_url_tablet || image_url_mobile

    const optimizedImageUrl =
        cover || (imageUrl && imageUrl.replace("upload/", "upload/h_1500,c_scale/"))

    return (
        <StyledLayout>
            <SideMenu menuItems={menuItems} />
            <StyledContent>
                <AnalysisBox>
                    <div id="infoPageContent">
                        <div className="section staticHero" id={removeSpaces(title || "")}>
                            <div className="heroBackground">
                                <div className="overlay" />
                                <Image
                                    src={optimizedImageUrl || "/images/background.jpg"}
                                    objectFit={"cover"}
                                    layout="fill"
                                />
                            </div>
                            {!!title && (
                                <div className="heroForeground pageTitle">
                                    <StyledH1 color="white" weight="bold">
                                        {title}
                                    </StyledH1>
                                </div>
                            )}
                        </div>

                        {!!body && <div id="approach" dangerouslySetInnerHTML={{ __html: body }} />}
                    </div>
                </AnalysisBox>
            </StyledContent>
        </StyledLayout>
    )
}

export default InfoPageComponent
