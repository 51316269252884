import { SideMenuItem } from "../types/menu"
import { removeSpaces } from "./index"

export const getMenuItems: () => SideMenuItem[] = () => {
    const menuItems: SideMenuItem[] = []
    document.querySelectorAll("h1, h2,h3")?.forEach((item) => {
        const itemWithType: HTMLElement = item as HTMLElement
        item.id = removeSpaces(itemWithType.innerText)

        const element: SideMenuItem = {
            className: item.tagName === "H2" || item.tagName === "H1" ? "mainItem" : "subItem",
            name: itemWithType.innerText,
            short_name: removeSpaces(itemWithType.innerText),
        }

        menuItems.push(element)
    })

    return menuItems
}
