import styled from "styled-components"

export const StyledBlock = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 640px;
    padding: 0 20px;
    text-align: center;

    + div {
        margin-left: 2%;

        & + div {
            padding-left: 0;
        }
    }

    .block-title {
        font-size: 100px;
        line-height: 1;

        @media (max-width: 1024px) {
            font-size: 60px;
        }
    }

    .block-top-subtitle {
        font-weight: 500;
        font-size: 26px;
    }

    .block-bottom-subtitle {
        margin: 0 0 30px;
    }
`
